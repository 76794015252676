<template>
    <footer class="bg-frontend-bg text-white text-center md:text-left">
        <div class="max-w-[1000px] mx-auto p-12 grid grid-cols-1 md:grid-cols-5 gap-4">
            <div class="col-span-1 mx-auto">
                <h3 class="footer-header">
                    Stay Connected
                </h3>
                <div class="flex gap-4 mt-4 mx-auto">
                    <template v-for="social in socials">
                        <a :href="social.link" class="hover:text-frontend-brand">
                            <component :is="social.icon" class="w-6 h-6" />
                        </a>
                    </template>
                </div>
            </div>
            <div class="col-span-1">
                <h3 class="footer-header">
                    Shop Parts
                </h3>
                <ul class="mt-4">
                    <template v-for="part in parts">
                        <li>
                            <a :href="part.link" class="hover:text-frontend-brand text-sm">
                                {{part.name}}
                            </a>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="col-span-1">
                <h3 class="footer-header">
                    Account Links
                </h3>
                <ul class="mt-4">
                    <template v-for="link in accountLinks">
                        <li>
                            <Link v-if="!theme.hasSubdomain" :href="link.link" class="hover:text-frontend-brand text-sm">
                                {{link.name}}
                            </Link>
                            <a v-else :href="baseUrl + link.link" class="hover:text-frontend-brand text-sm">
                                {{link.name}}
                            </a>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="col-span-1">
                <h3 class="footer-header">
                    Help & Support
                </h3>
                <ul class="mt-4">
                    <template v-for="link in supportLinks">
                        <li>
                            <Link v-if="!theme.hasSubdomain" :href="link.link" class="hover:text-frontend-brand text-sm">
                                {{link.name}}
                            </Link>
                            <a v-else :href="baseUrl + link.link" class="hover:text-frontend-brand text-sm">
                                {{link.name}}
                            </a>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="col-span-1">
                <h3 class="footer-header">
                    Company
                </h3>
                <ul class="mt-4">
                    <template v-for="info in companyInfo">
                        <li>
                            <span class="text-sm">
                                {{info.name}}
                            </span>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <hr class="border-white/80 border-[1px] rounded-full md:mx-8">
        <p class="text-center py-12 px-2 sm:px-40">Copyright 2006-{{ getYear() }} Quadzilla Limited registered in England with company number 06011790 and VAT registration number GB917743796.</p>
    </footer>
</template>

<script setup>
import Facebook from "@/Components/Frontend/Svg/Facebook.vue"
import Instagram from "@/Components/Frontend/Svg/Instagram.vue"
import Twitter from "@/Components/Frontend/Svg/Twitter.vue"
import Youtube from "@/Components/Frontend/Svg/youtube.vue"
import { Link } from '@inertiajs/vue3'
import {useThemeStore} from "@/stores/ThemeStore"

const theme = useThemeStore()

const baseUrl = 'https://quadzilla.co.uk'

const socials = [
    { name: 'Facebook', icon: Facebook, link: '#' },
    { name: 'Instagram', icon: Instagram, link: '#' },
    { name: 'Twitter', icon: Twitter, link: '#' },
    { name: 'Youtube', icon: Youtube, link: '#' },
]

const parts = [
    { name: 'Quadzilla', link: '#' },
    { name: 'Slam Bikes', link: '#' },
    { name: 'WK Bikes', link: '#' },
    { name: 'Visit our eBay Shop', link: '#' },
]

const accountLinks = [
    { name: 'My Account', link: '#' },
    { name: 'Warranty Registration', link: '#' },
    { name: 'Terms of Service', link: '#' },
    { name: 'Refund Policy', link: '#' },
]

const supportLinks = [
    { name: 'FAQs', link: '#' },
    { name: 'About Us', link: '#' },
    { name: 'Cookie Policy', link: '#' },
    { name: 'Privacy Policy', link: '#' },
    { name: 'Warranty & Servicing', link: '#' },
    { name: 'Delivery, Refunds & Returns', link: '#' },
    { name: 'Latest News', link: '#' },
    { name: 'Contact Us', link: '#' },
]

const companyInfo = [
    { name: 'Quadzilla Ltd' },
    { name: 'Lincolnshire' },
    { name: 'United Kingdom'}
]

const getYear = () => {
    return new Date().getFullYear();
}

</script>

<style scoped>
.footer-header {
    @apply text-lg text-white font-bold uppercase
}
</style>
