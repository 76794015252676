import {Currency, usePreferenceStore} from "../stores/PreferenceStore";
import {CartProduct} from "../stores/CartStore";

export const getPrice = (product: Product) => {
    const preferences = usePreferenceStore()
    const isGbp = preferences.isGbp
    const isVatIncluded = preferences.vatIncluded
    if(isGbp) return isVatIncluded ? product.prices.gbp_with_vat : product.prices.gbp_without_vat
    return isVatIncluded ? product.prices.eur_with_vat : product.prices.eur_without_vat
}

export const getFriendlyPrice = (product: Product) => {
    const preferences = usePreferenceStore()
    const isGbp = preferences.isGbp
    const isVatIncluded = preferences.vatIncluded
    if(isGbp) return isVatIncluded ? product.prices.gbp_friendly_with_vat : product.prices.gbp_friendly_without_vat
    return isVatIncluded ? product.prices.eur_friendly_with_vat : product.prices.eur_friendly_without_vat
}

export const getFriendlyMultiplier = (product: Product, quantity: number) => {
    const preferences = usePreferenceStore()
    const isGbp = preferences.isGbp
    const isVatIncluded = preferences.vatIncluded
    //return the currency symbol, and the price formatted with commas and two decimal places
    if(isGbp) return isVatIncluded ? `£${(product.prices.gbp_with_vat * quantity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : `£${(product.prices.gbp_without_vat * quantity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    return isVatIncluded ? `€${(product.prices.eur_with_vat * quantity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : `€${(product.prices.eur_without_vat * quantity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}

export const convertToFriendly = (price: number): string => {
    const preferences = usePreferenceStore()
    const isGbp = preferences.isGbp
    return isGbp ? `£${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : `€${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}

export const getVatTotal = (CartProducts: CartProduct[]) => {
    const preferences = usePreferenceStore()
    const isVatIncluded = preferences.vatIncluded
    if (!isVatIncluded) return 0

    const isGbp = preferences.isGbp
    let totalWithVat = 0
    let totalWithoutVat = 0
    if (isGbp) {
        CartProducts.forEach(p => {
            totalWithVat += p.product.prices.gbp_with_vat * p.quantity
            totalWithoutVat += p.product.prices.gbp_without_vat * p.quantity
        })
    } else {
        CartProducts.forEach(p => {
            totalWithVat += p.product.prices.eur_with_vat * p.quantity
            totalWithoutVat += p.product.prices.eur_without_vat * p.quantity
        })
    }
    return totalWithVat - totalWithoutVat
}

export const getSubtotal = (CartProducts: CartProduct[]) => {
    const preferences = usePreferenceStore()
    const isGbp = preferences.isGbp
    let total = 0
    if (isGbp) {
        CartProducts.forEach(p => {
            total += p.product.prices.gbp_without_vat * p.quantity
        })
    } else {
        CartProducts.forEach(p => {
            total += p.product.prices.eur_without_vat * p.quantity
        })
    }
    return total
}
