import { defineStore } from "pinia";
import {Ref, ref} from "vue";
import { getPrice } from "../util/vat";
import { Product } from "../util/product";
import { useToastStore } from "./ToastStore";
import Cookies from 'js-cookie'

export interface CartProduct {
    product: Product
    quantity: number
}

const cookiesStorage: Storage = {
    setItem (key, stateStr) {
        const state = JSON.parse(stateStr);
        return Cookies.set('cartProducts', JSON.stringify(state.cartProducts), { domain: getDomain() });
    },
    getItem (key) {
        const cartProducts = Cookies.get('cartProducts', { domain: getDomain() });
        return cartProducts ? JSON.stringify({ cartProducts: JSON.parse(cartProducts) }) : null;
    },
}
const getDomain = () => {
    const url: string = import.meta.env.VITE_APP_URL
    if(url !== undefined) return url.replace('https://', '').replace('http://', '').replace('/', '')
    return 'quadzillaquads.co.uk'
}
export const useCartStore = defineStore('cart-store', () => {
    const cartProducts = ref<CartProduct[]>([])
    const colorModalColor = ref('')
    const addingProduct : Ref<Product> = ref()
    const addingProductQuantity = ref(0)

    const addProduct = (product: Product, quantity: number) => {
        const toastStore = useToastStore()
        const index = cartProducts.value.findIndex(p => p.product.uuid === product.uuid)
        if (product.stock_level.message === 'Call Us') {
            toastStore.addToast('error', `Product ${product.name} is out of stock`)
            return
        }
        if (product.is_coloured_part && colorModalColor.value === '' && product.models[0].colour?.name) {
            colorModalColor.value = product.models[0].colour.name
            addingProduct.value = product
            addingProductQuantity.value = quantity
            return
        }

        if (index !== -1) cartProducts.value[index].quantity += quantity
        else cartProducts.value.push({product, quantity})

        toastStore.addToast('success', `Added ${quantity}x ${product.name} to cart`)

    }
    const removeProduct = (index: number) => {
        const toastStore = useToastStore()
        const product = cartProducts.value[index]
        cartProducts.value.splice(index, 1)
        toastStore.addToast('error', `Removed ${product.quantity}x ${product.product.name} from cart`)
    }
    const getCartTotal = () => {
        return cartProducts.value.reduce((acc, p) => acc + getPrice(p.product) * p.quantity, 0)
    }
    const clearCart = () => {
        cartProducts.value = []
    }
    return {
        cartProducts,
        addProduct,
        removeProduct,
        getCartTotal,
        colorModalColor,
        addingProduct,
        addingProductQuantity,
        clearCart
    }
}, {
    persist: {
        enabled: true,
        strategies: [
            {
                storage: cookiesStorage,
                paths: ['cartProducts']
            },
        ],
    },
})
