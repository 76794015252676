<template>
<div class="fixed top-4 right-0 sm:right-4 z-50 flex flex-col">
    <div >
        <transition-group name="slide-fade">
            <div v-for="toast in toasts" :key="toast.id" class=" md:w-96 shadow-lg rounded-md p-4 border text-white my-2" :class="toast.type === 'success' ? 'bg-green-700 border-green-300' : 'bg-red-700 border-red-300'">
                <div class="flex flex-row justify-between items-center gap-2">
                    <p class="font-semibold">{{ toast.message }}</p>
                    <button @click="toastStore.removeToast(toast.id)" class="text-white hover:text-white/50">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512" class="w-5 h-5"><path fill="currentColor" d="M256 512a256 256 0 1 0 0-512a256 256 0 1 0 0 512m-81-337c9.4-9.4 24.6-9.4 33.9 0l47 47l47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47l47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47l-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47l-47-47c-9.4-9.4-9.4-24.6 0-33.9"/></svg>
                    </button>
                </div>
            </div>
        </transition-group>
    </div>
</div>
</template>

<script setup lang="ts">
import { useToastStore } from "@/stores/ToastStore";
import {computed} from "vue";

const toastStore = useToastStore()

const toasts = computed(() => toastStore.toasts)
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
