<template>
    <div class="relative">

<!--        Header-->
        <div class="bg-frontend-brand h-[88px] md:h-[103px] text-white flex flex-row md:px-2 lg:px-6 2xl:px-12">
            <div class="flex flex-row justify-center md:justify-between items-center h-[40px] mt-5 w-full gap-4">
                <div class="w-1/3 flex-shrink h-full">
                    <Link :href="route('home')" class="h-full flex flex-col justify-center">
                        <img :src="theme.currentManufacturer.logo" :alt="theme.currentManufacturer.name" class="md:w-4/5 mt-3 object-scale-down"/>
                    </Link>
                </div>
                <div class="hidden md:block w-1/3 justify-center items-start">
                    <NavSearch :is-desktop="true" />
                    <p class="text-center" v-if="$page.props.auth.currentlyImpersonating">
                        You are currently impersonating <span class="font-semibold">{{ $page.props.auth.user.name }}</span>.
                    </p>
                </div>
                <div class="hidden md:flex flex-row gap-2 w-1/3 items-center justify-end">
                    <Link v-if="!theme.hasSubdomain && usePage()?.props?.auth?.user?.has_portal_access" :href="route('dashboard')" class="w-8 xl:w-10 p-2 hover:bg-gray-200/30 rounded-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                            <path fill-rule="evenodd" d="M15.75 1.5a6.75 6.75 0 0 0-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 0 0-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 0 0 .75-.75v-1.5h1.5A.75.75 0 0 0 9 19.5V18h1.5a.75.75 0 0 0 .53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1 0 15.75 1.5Zm0 3a.75.75 0 0 0 0 1.5A2.25 2.25 0 0 1 18 8.25a.75.75 0 0 0 1.5 0 3.75 3.75 0 0 0-3.75-3.75Z" clip-rule="evenodd" />
                        </svg>
                    </Link>
                    <a v-else-if="theme.hasSubdomain && usePage()?.props?.auth?.user?.has_portal_access" :href="route('dashboard')" class="w-8 xl:w-10 p-2 hover:bg-gray-200/30 rounded-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                            <path fill-rule="evenodd" d="M15.75 1.5a6.75 6.75 0 0 0-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 0 0-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 0 0 .75-.75v-1.5h1.5A.75.75 0 0 0 9 19.5V18h1.5a.75.75 0 0 0 .53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1 0 15.75 1.5Zm0 3a.75.75 0 0 0 0 1.5A2.25 2.25 0 0 1 18 8.25a.75.75 0 0 0 1.5 0 3.75 3.75 0 0 0-3.75-3.75Z" clip-rule="evenodd" />
                        </svg>
                    </a>

                    <Link v-if="!theme.hasSubdomain" :href="route('basket')" class="w-8 xl:w-10 p-2 hover:bg-gray-200/30 rounded-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                            <path fill="currentColor" d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                        </svg>
                    </Link>
                    <a v-else :href="route('basket')" class="w-8 xl:w-10 p-2 hover:bg-gray-200/30 rounded-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                            <path fill="currentColor" d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                        </svg>
                    </a>

                    <Link v-if="!theme.hasSubdomain" :href="route('account.show')" class="w-8 xl:w-10 p-2.5 hover:bg-gray-200/30 rounded-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                        </svg>
                    </Link>
                    <a v-else :href="route('account.show')" class="w-8 xl:w-10 p-2.5 hover:bg-gray-200/30 rounded-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                        </svg>
                    </a>
                    <DesktopToggle v-model:is-checked="eurEnabled" leftLabel="£" rightLabel="€" class=""/>
                    <DesktopToggle v-model:is-checked="vatEnabled" rightLabel="VAT" class=""/>
                </div>
            </div>
        </div>

<!--        Mobile Nav-->
        <div v-if="!searchOpen" class="absolute z-20 bg-white flex flex-row items-center justify-around md:justify-between text-black transform duration-200 px-4"
            :class="mobileMenuOpen? 'left-0 right-0 top-0 h-[88px] md:h-[103px] md:hidden' : 'left-[40px] right-[40px] top-[70px] md:top-[85px] h-[58px] lg:w-3/4 2xl:w-1/2 lg:left-1/2 lg:-translate-x-1/2'">
            <h2 class="md:hidden text-[20px] font-[800]">
                {{ routeName }}
            </h2>
            <button @click="mobileMenuOpen = !mobileMenuOpen" class="md:hidden absolute w-8 right-4 p-2 hover:bg-gray-200 rounded-lg">
                <svg class="transition transform duration-200" :class="mobileMenuOpen? '-rotate-90' : ''" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
                </svg>
            </button>

<!--            Desktop nav-->
            <div class="hidden md:flex gap-5 text-[12px] lg:text-[14px] md:font-[800] text-nowrap">
                <div v-for="item in navItems" :key="item.name">
                    <Link v-if="item.link !== ''" :href="item.link" class="text-center hover:text-frontend-brand">
                        {{ item.name }}
                    </Link>
                    <div v-else class="flex flex-col">
                        <button @click="manufacturersOpen = !manufacturersOpen"
                                class="flex gap-2 items-center justify-center">
                            <span class="hover:text-frontend-brand flex gap-1">
                                Manufacturers
                                <svg class="w-3 transition-transform transform duration-200"
                                     :class="manufacturersOpen? '-rotate-180' : ''" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 512 512">
                                    <path
                                        fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                                </svg>
                            </span>
                            <Transition>
                                <div v-if="manufacturersOpen" class="absolute grid grid-cols-2 font-[600] bg-white top-[58px] p-4 gap-y-6 gap-x-12">
                                    <Link v-for="item in manufacturers" :key="item.name" :href="`/products?manufacturers[]=${item.id}`" class="col-span-1 hover:text-frontend-brand">
                                        {{ item.name }}
                                    </Link>
                                </div>
                            </Transition>
                        </button>
                    </div>
                </div>
            </div>
            <div class="hidden md:flex gap-3">
                <Link v-for="item in socialMedia" target="_blank" :key="item.name" :href="item.link" class="text-[14px] font-[800] text-center">
                    <component :is="item.icon" class="w-5 h-5 hover:text-frontend-brand"/>
                </Link>
            </div>
        </div>

        <Transition>
            <div v-if="mobileMenuOpen" class="pb-8 md:hidden">
                <div v-for="item in navItems" :key="item.name" class="text-[14px] font-[800] text-center flex flex-col justify-center text-black">
                    <Link v-if="item.link !== ''" :href="item.link" class="my-2">
                        {{ item.name }}
                    </Link>
                    <div v-else class="mx-auto my-2">
                        <button @click="manufacturersOpen = !manufacturersOpen" class="flex gap-2 items-center justify-center">
                            Manufacturers
                            <svg class="w-3 text-black transition transform duration-200" :class="manufacturersOpen? '-rotate-180' : ''" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                            </svg>
                        </button>
                        <div v-if="manufacturersOpen" class="flex flex-col font-[600] mt-2">
                            <Link v-for="item in manufacturers" :key="item.name" :href="`/products?manufacturers[]=${item.id}`">
                                {{ item.name }}
                            </Link>
                        </div>
                    </div>
                </div>
                <NavSearch class="mt-2" />
                <p class="text-center text-frontend-brand mt-4">MON - FRI 9:00AM - 5:30PM</p>
                <div class="flex flex-row text-frontend-brand justify-center mt-4 gap-2">
                    <Link :href="route('basket')" class="w-10 p-2 hover:bg-gray-200 rounded-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
                    </Link>
                    <Link :href="route('account.show')" class="w-10 p-2.5 hover:bg-gray-200 rounded-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                    </Link>
                </div>
                <MobileToggle v-model:is-checked="eurEnabled" leftLabel="£" rightLabel="€" class="mx-auto mt-4"/>
                <MobileToggle v-model:is-checked="vatEnabled" leftLabel="Inc. VAT" rightLabel="Exc. VAT" class="mx-auto mt-4"/>
            </div>
        </Transition>
    </div>
</template>
<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import {computed, onMounted, ref, watchEffect} from "vue"
import { usePage } from "@inertiajs/vue3"
import NavSearch from "@/Components/Frontend/Nav/NavSearch.vue"
import MobileToggle from "@/Components/Frontend/Nav/MobileToggle.vue"
import DesktopToggle from "@/Components/Frontend/Nav/DesktopToggle.vue";
import Facebook from "@/Components/Frontend/Svg/Facebook.vue"
import Instagram from "@/Components/Frontend/Svg/Instagram.vue"
import Twitter from "@/Components/Frontend/Svg/Twitter.vue"
import Youtube from "@/Components/Frontend/Svg/youtube.vue"
import {usePreferenceStore} from "@/stores/PreferenceStore";
import {useThemeStore} from "@/stores/ThemeStore";
import {Manufacturer} from "@/util/model";
import {useFetch} from "@vueuse/core";

const preferences = usePreferenceStore()
const theme = useThemeStore()

const mobileMenuOpen = ref(false)
const manufacturersOpen = ref(false)
const eurEnabled = ref(false)
const vatEnabled = ref(false)
if (preferences.currency === "EUR") eurEnabled.value = true
if (preferences.vatIncluded) vatEnabled.value = true

defineProps({
    isDesktop: {
        type: Boolean,
        default: false
    }
})

const searchOpen = computed(() => preferences.searchOpen)

const page = usePage();
const routeName = computed(() => {
    const route = page.url as string
    if (route === "/") return "Home"
    if (route.includes("/products/")) return "Product"
    if (route.includes("Service%20Kit")) return "Service Kits"
    if (route.includes("Merchandise")) return "Merchandise"
    if (route.includes("Oils%20and%20Lubricants")) return "Oils and Lubricants"
    if (route.includes("/products")) return "Products"
    if (route.includes("/service-schedule")) return "Service Schedule"
    return route.split('/').pop().replace(/-/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())
})

//Toggles preferences in store
watchEffect(() => {
    preferences.currency = eurEnabled.value? "EUR" : "GBP"
    preferences.vatIncluded = vatEnabled.value
})

//watch window size, if it changes at all, set manufacturersOpen to false
watchEffect(() => {
    window.addEventListener('resize', () => manufacturersOpen.value = false)
})

const navItems = [
    {
        name: "Home",
        link: "/",
    },
    {
        name: "About",
        link: "/about-us",
    },
    {
        name: "Manufacturers",
        link: "",
    },
    {
        name: "Merchandise",
        link: "/products?tags=Merchandise",
    },
    {
        name: "Service Kits",
        link: "/products?parts_types=Service Kit",
    },
    {
        name: "Lubricants & Oils",
        link: "/products?tags=Oils and Lubricants",
    },
]

if (theme.hasSubdomain) {
    navItems.splice(navItems.findIndex(item => item.name === 'Home'), 1);
    navItems.splice(navItems.findIndex(item => item.name === 'About'), 1);
    navItems.splice(navItems.findIndex(item => item.name === 'Manufacturers'), 1);
    navItems.splice(0, 0, {
        name: theme.currentManufacturer.name + ' Products',
        link: '/products',
    });
}

const manufacturers = ref<Manufacturer[]>()
onMounted(async () => {
    const { isFetching, error, data } = await useFetch('/api/manufacturers').get().json()
    if (data.value) {
        manufacturers.value = data.value.data as Manufacturer[]
    }
})

const socialMedia = [
    {
        name: "Facebook",
        link: "https://www.facebook.com/quadzillaquads",
        icon: Facebook
    },
    {
        name: "Instagram",
        link: "https://www.instagram.com/quadzillaquads",
        icon: Instagram
    },
    {
        name: "Twitter",
        link: "https://www.twitter.com/quadzillaquads",
        icon: Twitter
    },
    {
        name: "YouTube",
        link: "https://www.youtube.com/quadzillaquads",
        icon: Youtube
    }
]
</script>

<style scoped>
.v-leave-active,
.v-enter-active{
    transition: all 0.2s ease-in;
}

.v-enter-from{
    @media only screen and (max-width: 767px) {
        opacity: 0;
        transform: translateY(-300px);
    }
    @media only screen and (min-width: 768px) {
        opacity: 0;
    }
}
.v-leave-to {
    @media only screen and (max-width: 767px) {
        opacity: 0;
        transform: translateY(-300px);
    }
    @media only screen and (min-width: 768px) {
        opacity: 0;
    }
}
</style>
