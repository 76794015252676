
export const manufacturerConfig: Manufacturer[] = [
    {
        name: 'Quadzilla',
        headline: 'Engineered To Exhilarate',
        hero: 'Quadzilla is the UK\'s largest supplier of on and off-road quads and buggies. Our award winning company has been the market leader for over 10 years.',
        subdomain: '',
        logo: '/images/quadzilla-logo.png',
        theme: {
            'frontend-brand': '194 41 23',
            'frontend-header': '22 28 43',
            'frontend-bg': '44 55 85',
        }
    },
    {
        name: 'CFMOTO',
        headline: 'The ATV & UTV Range',
        hero: 'CFMOTO is a ATV and Side x Side Manufacturer. We provide people with quality powersport vehicles such as our CFORCE ATVs & ZFORCE Side x Sides. 763-398-2690',
        subdomain: 'cfmoto',
        logo: '/frontend/CFMOTO-logo.svg',
        theme: {
            'frontend-brand': '49 155 180',
            'frontend-header': '0 0 0',
            'frontend-bg': '0 0 0',
        }
    }
]
export const defaultManufacturer = manufacturerConfig[0]

export function getManufacturer(subdomain: string): Manufacturer {
    const dealer = manufacturerConfig.find(dealer => dealer.subdomain === subdomain)
    if (dealer) return dealer
    return manufacturerConfig[0]
}

export interface Manufacturer {
    name: string
    headline: string
    hero: string
    subdomain: string
    theme: {
        'frontend-brand': string
        'frontend-header': string
        'frontend-bg': string
    }
}
